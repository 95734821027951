import { Avatar, Space } from "antd";
import { get_api, put_api } from "api";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { startCase } from "lodash";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/optom-logo-small.png";

const useBusinessUsersListHook = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [companyProfileList, setCompanyProfileList] = useState(false);
  const [CompanyProfileUnlisted, setCompanyProfileUnlisted] = useState(false);
  const [tabKey, setTabKey] = useState('1');
  const [unListedRowData, setUnListedRowData] = useState({});

  const getAllBusinessUsers = async () => {
    try {
      const { data } = await get_api("company-profile");
      setCompanyProfileList(data.companyProfiles);
      const dataUnlistedUsers = await get_api("company-profile/unlistedProfiles");
      console.log('dataUnlistedUsers: ', dataUnlistedUsers);
     
      const transformedDataUnlistedUsers = {
        companyProfiles: dataUnlistedUsers?.data?.usersWithoutCompanyProfiles.map(profile => ({
          ...profile,
          ...profile?.companyProfileDetails,
          id: profile.id,
        }))
      };
      console.log('transformedDataUnlistedUsers: ', transformedDataUnlistedUsers);

      setCompanyProfileList(data.companyProfiles);
      setCompanyProfileUnlisted(transformedDataUnlistedUsers.companyProfiles );

      setIsLoading(false);
      return data;
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getAllBusinessUsers();
  }, []);

  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      render: (text, record) => (
        <div key={record._id} className="tbl-user-details">
          <Avatar
            icon={<UserOutlined />}
            src={record?.companyLogo ? record?.companyLogo : logo}
          />
          <h6 className="m-0 text-primary text-capitalize">{text}</h6>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => {
        console.log('record: ', record);
        return(
        <div key={record._id}>
          <div className="fields-wrapper">
            {record?.contact?.email && (
              <span className="m-0">{record.contact.email}</span>
            )}
            {record?.email && (
              <span className="m-0">{record.email}</span>
            )}
          </div>
        </div>
      )},
      align: "center",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (text, record) => (
        <div key={record._id}>
          <div className="fields-wrapper">
            {record?.contact?.phone && (
              <p className="m-0">{record.contact.phone}</p>
            )}
          </div>
        </div>
      ),
      align: "center",
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
      render: (text, record) => (
        <div key={record._id}>
          <div className="fields-wrapper">
            {record?.contact?.website && (
              <span
                className="m-0"
                onClick={() =>
                  window.open(
                    record?.contact?.website.includes("http")
                      ? record?.contact?.website
                      : `https://${record?.contact?.website}`,
                    "_blank"
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {record?.contact?.website}
              </span>
            )}
          </div>
        </div>
      ),
      align: "center",
    },
    {
      title: "Plan",
      dataIndex: "pricingPlan",
      key: "pricingPlan",
      render: (text, record) => (
        <div key={record._id}>
          <div>
            {record?.pricingPlan?.productIntervalType === "month"
              ? "Monthly"
              : record?.pricingPlan?.productIntervalType === "year"
                ? "Yearly"
                : ""}
          </div>
          <span>
            {record?.pricingPlan?.cancelAt
              ? "Cancelled"
              : record?.pricingPlan?.subscriptionStatus !== "trialing"
                ? startCase(record?.pricingPlan?.subscriptionStatus)
                : "7 Days Free Trial"}
          </span>
        </div>
      ),
      align: "center",
    },
    {
      title: "Total Shifts",
      dataIndex: "total_shifts",
      key: "total_shifts",
      render: (text, record) => (
        <div
          key={record._id}
          style={{ cursor: "pointer", fontWeight: "bold" }}
          onClick={() => navigate(`/business-users/${record._id}/post-shift`)}
        >
          {record?.post_shift_ids?.length}
        </div>
      ),
      align: "center",
    },
    {
      title: "Registered On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div key={record._id}>{dayjs(text).format("DD-MM-YYYY")}</div>
      ),
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size={[8, 0]} key={record._id}>
          <i
            className="fas fa-eye"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if(tabKey === "1") {
                navigate(`/business-users/${record._id}/view`)
              } else if(tabKey === "2") {
                setUnListedRowData(record)
              }
            }}
          />
        </Space>
      ),
    },
  ];

  return {
    columns,
    companyProfileList,
    isLoading,
    CompanyProfileUnlisted,
    tabKey, 
    setTabKey,
    unListedRowData,
    setUnListedRowData
  };
};

export default useBusinessUsersListHook;
