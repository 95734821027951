import { Spin, Divider } from "antd";
import React from "react";
import useCompanyProfileHook from "./useCompanyProfileHook";
import { Card, CardBody, Col, Form, FormGroup, Input, Row } from "reactstrap";
import dayjs from "dayjs";

const CompanyProfile = () => {
  const { isLoading, companyProfileData } = useCompanyProfileHook();
  return (
    <div className="content mb-0">
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Row>
          <Col md="12">
            <Card className="card-user">
              <CardBody>
                <Form>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">Company</label>
                        <Input
                          className="input-value"
                          value={companyProfileData?.companyName}
                          placeholder="Company"
                          type="text"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">Email address</label>
                        <Input
                          className="input-value"
                          placeholder="Email"
                          type="email"
                          value={companyProfileData?.contact?.email}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">Website</label>
                        <Input
                          className="input-value"
                          value={companyProfileData?.contact?.website}
                          placeholder="Website"
                          type="text"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">Phone</label>
                        <Input
                          className="input-value"
                          placeholder="Phone"
                          value={companyProfileData?.contact?.phone}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">Testing Time</label>
                        <Input
                          className="input-value"
                          placeholder="Testing Time"
                          value={companyProfileData?.testingTime}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">Vaccinated: </label>
                        {/* <Input
                          placeholder="Vaccinated"
                          type="checkbox"
                          checked={companyProfileData?.isVaccinated}
                          className="ml-3"
                        /> */}
                        <span className="static-value">
                          {companyProfileData?.isVaccinated ? "Yes" : "No"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">Services</label>
                        {companyProfileData?.services?.map((srvs, idx) => {
                          return (
                            <p className="text-capitalize mb-0">
                              {idx + 1}. {srvs.name}
                            </p>
                          );
                        })}
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">Softwares</label>
                        {companyProfileData?.software?.map((sftwr, idx) => {
                          return (
                            <p className="text-capitalize mb-0">
                              {idx + 1}. {sftwr.name}
                            </p>
                          );
                        })}
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">Equipments</label>
                        {companyProfileData?.equipment?.map((eqpmnt, idx) => {
                          return (
                            <p className="text-capitalize mb-0">
                              {idx + 1}. {eqpmnt.name}
                            </p>
                          );
                        })}
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">
                          Additional Requirements
                        </label>
                        {companyProfileData?.additionalRequirements?.map(
                          (adReq, idx) => {
                            return (
                              <p className="text-capitalize mb-0">
                                {idx + 1}. {adReq}
                              </p>
                            );
                          }
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">
                          Primary Language
                        </label>
                        <p className="text-capitalize mb-0">
                          {(companyProfileData && companyProfileData?.language.length > 0) ? companyProfileData?.language?.[0].language : ""}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">
                          Secondary Language
                        </label>
                        {companyProfileData?.language
                          ?.splice(1)
                          ?.map((sec, idx) => {
                            return (
                              <p className="text-capitalize mb-0">
                                {idx + 1}. {sec?.language}
                              </p>
                            );
                          })}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Divider />
                  {companyProfileData?.pricingPlan && (
                    <div className="card-sec">
                      <h4 className="card-sec-title">Pricing Details</h4>
                      <Row>
                        <Col md="3">
                          <FormGroup>
                            <label className="card-sub-title">
                              Subscription Type:{" "}
                            </label>
                            <p className="text-capitalize mb-0">
                              {companyProfileData?.pricingPlan
                                ?.productIntervalType === "month"
                                ? "Monthly"
                                : "Yearly"}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <label className="card-sub-title">Amount: </label>
                            <p className="text-capitalize mb-0">
                              ${companyProfileData?.pricingPlan?.totalPaid}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <label className="card-sub-title">
                              Subscription Status:{" "}
                            </label>
                            <p className="text-capitalize mb-0">
                              {companyProfileData?.pricingPlan?.cancelAt
                                ? "Cancelled"
                                : companyProfileData?.pricingPlan
                                  ?.subscriptionStatus}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <label className="card-sub-title">
                              Subscription Id:{" "}
                            </label>
                            <p className="text-capitalize mb-0">
                              {companyProfileData?.pricingPlan?.subscriptionId}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="3">
                          <FormGroup>
                            <label className="card-sub-title">
                              Trial Start:{" "}
                            </label>
                            <p className="text-capitalize mb-0">
                              {dayjs
                                .unix(
                                  companyProfileData?.pricingPlan
                                    ?.trialPeriodStartDate
                                )
                                .format("DD-MM-YYYY")}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <label className="card-sub-title">
                              Trial End:{" "}
                            </label>
                            <p className="text-capitalize mb-0">
                              {dayjs
                                .unix(
                                  companyProfileData?.pricingPlan
                                    ?.trialPeriodEndDate
                                )
                                .format("DD-MM-YYYY")}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <label className="card-sub-title">
                              Invoice Status:{" "}
                            </label>
                            <p className="text-capitalize mb-0">
                              {companyProfileData?.pricingPlan?.invoiceStatus}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <label className="card-sub-title">
                              Discount Coupon:{" "}
                            </label>
                            <p className="text-capitalize mb-0">
                              {companyProfileData?.pricingPlan?.couponName ? companyProfileData?.pricingPlan?.couponName : "-"}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <label className="card-sub-title">
                              Discount Coupon Id:{" "}
                            </label>
                            <p className="text-capitalize mb-0">
                              {companyProfileData?.pricingPlan?.couponCodeId ? companyProfileData?.pricingPlan?.couponCodeId : "-"}
                            </p>
                          </FormGroup>
                        </Col>
                        {/* <Col md="3">
                        <label className="card-sub-title">Subscription Id: </label>
                        <p className="text-capitalize mb-0">
                          {companyProfileData?.pricingPlan?.subscriptionId}
                        </p>
                      </Col> */}
                      </Row>
                    </div>
                  )}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default CompanyProfile;
