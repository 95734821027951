import { Spin, Table, Input } from "antd";
import { get_api } from "api";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardBody, Row, Col } from "reactstrap";

function Dashboard() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState({});
  const [shiftsData, setShiftsData] = useState([]);
  const [selectedShiftType, setSelectedShiftType] = useState("");
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });

  useEffect(() => {
    getDashboardCounts();
  }, []);

  const getDashboardCounts = async () => {
    try {
      setIsLoading(true);
      const { data } = await get_api(`dashboard`);
      setDashboardData(data || {});
    } catch (err) {
      console.error("Error fetching dashboard counts:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchShifts = async (type, page = 1, search = searchText) => {
    setIsLoading(true);
    try {
      const data = await get_api(`dashboard/${type}?page=${page}&limit=${pagination.pageSize}&search=${search}`);
      console.log("🚀 ~ fetchShifts ~ data:", data)

      setShiftsData(data.shifts || []);
      setPagination((prev) => ({ ...prev, current: page, total: data.total || 0 }));
      setSelectedShiftType(type);
    } catch (err) {
      console.error("Error fetching shifts:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
    fetchShifts(selectedShiftType, 1, value);
  };

  const handleTableChange = (pagination) => {
    fetchShifts(selectedShiftType, pagination.current, searchText);
  };

  const columns = {
    "requested-shifts": [
      { title: "Locum Name", dataIndex: "locum_name", key: "locum_name" },
      { title: "Store Name", dataIndex: "store_name", key: "store_name" },
      { title: "Shift Date", dataIndex: "shift_date", key: "shift_date" },
      { title: "Start Time", dataIndex: "start_time", key: "start_time" },
      { title: "End Time", dataIndex: "end_time", key: "end_time" },
      { title: "Hourly Rate", dataIndex: "hourly_rate", key: "hourly_rate" },
    ],
    "accepted-shifts": [
      { title: "Locum Name", dataIndex: "locum_name", key: "locum_name" },
      { title: "Store Name", dataIndex: "store_name", key: "store_name" },
      { title: "Shift Date", dataIndex: "shift_date", key: "shift_date" },
      { title: "Start Time", dataIndex: "start_time", key: "start_time" },
      { title: "End Time", dataIndex: "end_time", key: "end_time" },
      { title: "Hourly Rate", dataIndex: "hourly_rate", key: "hourly_rate" },
    ],
    "declined-shifts": [
      { title: "Locum Name", dataIndex: "locum_name", key: "locum_name" },
      { title: "Store Name", dataIndex: "store_name", key: "store_name" },
      { title: "Shift Date", dataIndex: "shift_date", key: "shift_date" },
      { title: "Start Time", dataIndex: "start_time", key: "start_time" },
      { title: "End Time", dataIndex: "end_time", key: "end_time" },
      { title: "Hourly Rate", dataIndex: "hourly_rate", key: "hourly_rate" },

    ],
  };

  return (
    <div className="content dashboard-content">
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" />
        </div>
      )}
      
      {!isLoading && (
        <>
          <Row>
            <Col lg="3" md="6" sm="6">
              <Card
                className="card-stats dashboard-cards bg-light-yellow"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => navigate("/business-users")}
              >
                <CardBody>
                  <div className="card-head-wrap">
                    <h6 className="card-title">Business Users</h6>
                  </div>
                  <div className="card-foot-details">
                    <h4 className="total-figure">{dashboardData.company}</h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card
                className="card-stats dashboard-cards bg-light-purple"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => navigate("/locum-users")}
              >
                <CardBody>
                  <div className="card-head-wrap">
                    <h6 className="card-title">Locums</h6>
                  </div>
                  <div className="card-foot-details">
                    <h4 className="total-figure">{dashboardData.locum}</h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card
                className="card-stats dashboard-cards bg-light-cyan"
                style={{
                  cursor: "pointer",
                }}
              >
                <CardBody>
                  <div className="card-head-wrap">
                    <h6 className="card-title">Post Shifts</h6>
                  </div>
                  <div className="card-foot-details">
                    <h4 className="total-figure">
                      {dashboardData.postshift}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card
                className="card-stats dashboard-cards bg-green"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => navigate("/ahpra-requests")}
              >
                <CardBody>
                  <div className="card-head-wrap">
                    <h6 className="card-title">Ahpra Requests</h6>
                  </div>
                  <div className="card-foot-details">
                    <h4 className="total-figure">
                      {dashboardData.unverfiedAphra}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="3" md="6" sm="6">
              <Card
                className="card-stats dashboard-cards bg-light-pink"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => fetchShifts("requested-shifts")}
              >
                <CardBody>
                  <div className="card-head-wrap">
                    <h6 className="card-title">Requested Shifts</h6>
                  </div>
                  <div className="card-foot-details">
                    <h4 className="total-figure">
                      {dashboardData.requestedShiftsCount}
                    </h4>
                    <span className="card-click-link">Click here for more details</span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card
                className="card-stats dashboard-cards bg-light-green"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => fetchShifts("accepted-shifts")}
              >
                <CardBody>
                  <div className="card-head-wrap">
                    <h6 className="card-title">Accepted Shifts</h6>
                  </div>
                  <div className="card-foot-details">
                    <h4 className="total-figure">
                      {dashboardData.activeShiftsCount}
                    </h4>
                    <span className="card-click-link">Click here for more details</span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card
                className="card-stats dashboard-cards bg-light-red"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => fetchShifts("declined-shifts")}
              >
                <CardBody>
                  <div className="card-head-wrap">
                    <h6 className="card-title">Declined Shifts</h6>
                  </div>
                  <div className="card-foot-details">
                    <h4 className="total-figure">
                      {dashboardData.declinedShiftsCount}
                    </h4>
                    <span className="card-click-link">Click here for more details</span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card
                className="card-stats dashboard-cards bg-light-blue"
                style={{
                  cursor: "pointer",
                }}
              >
                <CardBody>
                  <div className="card-head-wrap">
                    <h6 className="card-title">Saved Shifts</h6>
                  </div>
                  <div className="card-foot-details">
                    <h4 className="total-figure">
                      {dashboardData.savedShiftsCount}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {selectedShiftType && (
            <>
              <Input.Search
                placeholder="Search by Locum Name or Store Name"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onSearch={handleSearch}
                style={{ marginBottom: 16, width: "300px" }}
              />
              <Table
                className={selectedShiftType}
                dataSource={shiftsData}
                columns={columns[selectedShiftType]}
                pagination={pagination}
                onChange={handleTableChange}
                rowKey={(record, index) => index}
                scroll={{ x: "max-content" }}
              />

            </>
          )}
        </>
      )}
    </div>
  );
}

export default Dashboard;
