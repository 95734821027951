import { Modal, Spin, Table, Tabs } from "antd";
import React from "react";
import useBusinessUsersListHook from "./useBusinessUsersListHook";
import { isEmpty } from "lodash";
import { Col, Form, FormGroup, Input, Row } from "reactstrap";

const BusinessUsersList = () => {
  const { columns, companyProfileList, isLoading, CompanyProfileUnlisted, tabKey, setTabKey, unListedRowData, setUnListedRowData } = useBusinessUsersListHook();
  const listedUsers = companyProfileList
  const nonListedUsers = CompanyProfileUnlisted
  console.log('nonListedUsers: ', nonListedUsers);
  return (
    <div className="content mb-0">
      <h2 className="page-main-title">Business Users</h2>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Tabs defaultActiveKey={tabKey} onChange={setTabKey} type="card">
          <Tabs.TabPane tab={"Registered Users" + " (" + listedUsers.length + ")"} key="1">
            <Table
              rowClassName={() => "custom-table-row"}
              columns={columns}
              dataSource={listedUsers}
              rowKey="_id"
              pagination={false}
              scroll={
                listedUsers.length > 0 ? { y: 670, x: 1300 } : undefined
              }
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Non-Registered Users" + " (" + nonListedUsers.length + ")"} key="2">
            <Table
              rowClassName={() => "custom-table-row"}
              columns={columns}
              dataSource={nonListedUsers}
              rowKey="_id"
              pagination={false}
              scroll={
                nonListedUsers.length > 0 ? { y: 670, x: 1300 } : undefined
              }
            />
          </Tabs.TabPane>
        </Tabs>
      )}
      <Modal
        className="common-admin-popup"
        open={!isEmpty(unListedRowData)}
        onCancel={() => {
          setUnListedRowData({});
        }}
        centered
        destroyOnClose
        // onOk={handleSubmitData}
        width={1200}
        footer={null}
      >
        <Form>
          <Row>
            <Col md="4">
              <FormGroup>
                <label className="card-sub-title">Company</label>
                <Input
                  className="input-value"
                  value={unListedRowData?.companyName}
                  placeholder="Company"
                  type="text"
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label className="card-sub-title">Email address</label>
                <Input
                  className="input-value"
                  placeholder="Email"
                  type="email"
                  value={unListedRowData?.contact?.email}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label className="card-sub-title">Website</label>
                <Input
                  className="input-value"
                  value={unListedRowData?.contact?.website}
                  placeholder="Website"
                  type="text"
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <FormGroup>
                <label className="card-sub-title">Phone</label>
                <Input
                  className="input-value"
                  placeholder="Phone"
                  value={unListedRowData?.contact?.phone}
                  disabled
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label className="card-sub-title">Testing Time</label>
                <Input
                  className="input-value"
                  placeholder="Testing Time"
                  value={unListedRowData?.testingTime}
                  disabled
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label className="card-sub-title">Vaccinated: </label>
                {/* <Input
                          placeholder="Vaccinated"
                          type="checkbox"
                          checked={unListedRowData?.isVaccinated}
                          className="ml-3"
                        /> */}
                <span className="static-value">
                  {unListedRowData?.isVaccinated ? "Yes" : "No"}
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <FormGroup>
                <label className="card-sub-title">Services</label>
                {unListedRowData?.services?.map((srvs, idx) => {
                  return (
                    <p className="text-capitalize mb-0">
                      {idx + 1}. {srvs.name}
                    </p>
                  );
                })}
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label className="card-sub-title">Softwares</label>
                {unListedRowData?.software?.map((sftwr, idx) => {
                  return (
                    <p className="text-capitalize mb-0">
                      {idx + 1}. {sftwr.name}
                    </p>
                  );
                })}
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label className="card-sub-title">Equipments</label>
                {unListedRowData?.equipment?.map((eqpmnt, idx) => {
                  return (
                    <p className="text-capitalize mb-0">
                      {idx + 1}. {eqpmnt.name}
                    </p>
                  );
                })}
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label className="card-sub-title">
                  Additional Requirements
                </label>
                {unListedRowData?.additionalRequirements?.map(
                  (adReq, idx) => {
                    return (
                      <p className="text-capitalize mb-0">
                        {idx + 1}. {adReq}
                      </p>
                    );
                  }
                )}
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label className="card-sub-title">
                  Primary Language
                </label>
                <p className="text-capitalize mb-0">
                  {unListedRowData && unListedRowData?.language?.[0]?.language}
                </p>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label className="card-sub-title">
                  Secondary Language
                </label>
                {unListedRowData?.language
                  ?.splice(1)
                  ?.map((sec, idx) => {
                    return (
                      <p className="text-capitalize mb-0">
                        {idx + 1}. {sec?.language}
                      </p>
                    );
                  })}
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default BusinessUsersList;
